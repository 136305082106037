export function Bag({ color, mobile = false }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={mobile ? "bag-mobile" : "lol"}
        width="25"
        height="24"
        style={{ marginLeft: "8px" }}
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M20.46 8.9599C19.79 8.2199 18.78 7.7899 17.38 7.6399V6.8799C17.38 5.5099 16.8 4.1899 15.78 3.2699C14.75 2.3299 13.41 1.8899 12.02 2.0199C9.62999 2.2499 7.61999 4.5599 7.61999 7.0599V7.6399C6.21999 7.7899 5.20999 8.2199 4.53999 8.9599C3.56999 10.0399 3.59999 11.4799 3.70999 12.4799L4.40999 18.0499C4.61999 19.9999 5.40999 21.9999 9.70999 21.9999H15.29C19.59 21.9999 20.38 19.9999 20.59 18.0599L21.29 12.4699C21.4 11.4799 21.42 10.0399 20.46 8.9599ZM12.16 3.4099C13.16 3.3199 14.11 3.6299 14.85 4.2999C15.58 4.9599 15.99 5.8999 15.99 6.8799V7.5799H9.00999V7.0599C9.00999 5.2799 10.48 3.5699 12.16 3.4099ZM8.91999 13.1499H8.90999C8.35999 13.1499 7.90999 12.6999 7.90999 12.1499C7.90999 11.5999 8.35999 11.1499 8.90999 11.1499C9.46999 11.1499 9.91999 11.5999 9.91999 12.1499C9.91999 12.6999 9.46999 13.1499 8.91999 13.1499ZM15.92 13.1499H15.91C15.36 13.1499 14.91 12.6999 14.91 12.1499C14.91 11.5999 15.36 11.1499 15.91 11.1499C16.47 11.1499 16.92 11.5999 16.92 12.1499C16.92 12.6999 16.47 13.1499 15.92 13.1499Z"
          fill={color ? color : "white"}
        />
      </svg>
      <style jsx="true">
        {`
          .bag-mobile {
            @media (max-width: 800px) {
              width: 14px;
              height: 14px;
            }
          }
        `}
      </style>
    </>
  );
}
